import React, {useRef, useState} from 'react';
import './App.css'
import Icon, {HeartFilled, HeartOutlined} from '@ant-design/icons';
import {SmileOutline} from "antd-mobile-icons";
import {Button, Result} from "antd-mobile";

function generateMap() {
    const _t = []
    const randomColor = ['pink', '#13c2c2',]
    for (let i = 0; i < 30; i++) {
        const _lines = []
        for (let j = 0; j < 15; j++) {
            _lines.push(
                {
                    bcgColor: randomColor[Math.floor(Math.random() * randomColor.length)],
                    content: ``
                }
            )
        }
        _t.push(_lines)
    }
    return _t;
}

export default function App(props){
    const [map, setMap] = useState(generateMap())
    const searchedBlock = useRef(new Set());
    const audioRef = useRef();
    const clickTimes = useRef(0);
    const xiaoGrayArea = (map)=>{
        map = map.map(line=>{
            line = line.filter(block=> block.content !== 'BOOM');
            return line;
        })
        map = map.filter(line=> line.length > 0)
        return map;
    }
    const onClickFunction = (block, lineNumber, columnNumber) => {
        clickTimes.current++;
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        const grayArea = []
        boomFunction(block,map, grayArea, lineNumber, columnNumber)
        // if (grayArea.length > 1){
        grayArea.forEach(e=>{
            // e.bcgColor = 'gray';
            e.content= 'BOOM';
        })
        const newMap = xiaoGrayArea(map)
        setMap([...newMap])
        searchedBlock.current = new Set()
    }



    const boomFunction = (block, map, grayArea, lineNumber, columnNumber) =>
    {
        grayArea.push(block)
        const upperBlock = map[lineNumber-1]?.[columnNumber];
        const downBlock = map[lineNumber+1]?.[columnNumber];
        const leftBlock = map[lineNumber]?.[columnNumber-1];
        const rightBlock = map[lineNumber]?.[columnNumber+1];

        searchedBlock.current.add(block)
        if (upperBlock && block.bcgColor === upperBlock?.bcgColor && !searchedBlock.current.has(upperBlock)){
            boomFunction(upperBlock, map, grayArea, lineNumber-1,columnNumber)
        }
        if (downBlock && block.bcgColor === downBlock?.bcgColor && !searchedBlock.current.has(downBlock)){
            boomFunction(downBlock, map, grayArea, lineNumber+1, columnNumber)
        }
        if (leftBlock && block.bcgColor === leftBlock?.bcgColor && !searchedBlock.current.has(leftBlock)){
            boomFunction(leftBlock, map, grayArea, lineNumber, columnNumber-1)
        }

        if (rightBlock && block.bcgColor === rightBlock?.bcgColor && !searchedBlock.current.has(rightBlock)){
            boomFunction(rightBlock, map, grayArea, lineNumber, columnNumber+1)
        }

    }



    return (

        <div id={'xiaoxiao'} >
            <audio src={'./Voyager.ogg'} ref={audioRef}/>
            <div>
            {
                map.map((line, lineNumber)=>{
                    return (
                        <div className={'flex'}>
                            {line.map((column, columnNumber)=>{
                                return (
                                    column.content !== 'BOOM' ?
                                        <HeartOutlined style={{color: column.bcgColor, fontSize: '6vw'}}
                                                       onClick={() => onClickFunction(column, lineNumber, columnNumber,  )}>
                                            {column.content}
                                        </HeartOutlined> :
                                        <HeartFilled style={{color: column.bcgColor, fontSize: '6vw'}}
                                                     onClick={() => onClickFunction(column)}>
                                            {column.content}
                                        </HeartFilled>
                                )
                            })}
                        </div>
                    )
                })
            }
                {
                    map.every(e=> e?.length === 0) &&
                    <>
                        <Result
                            icon={<SmileOutline />}
                            status='success'
                            title='你赢了'
                            description={`你用了${clickTimes.current}个炸弹`}
                        />
                        <Button type={'primary'} onClick={()=> {
                            setMap(generateMap)
                            clickTimes.current = 0;
                        }}>再来一局</Button>
                    </>
                }
            </div>

        </div>
    )
}
